import {css, Theme} from '@emotion/react';
import styled from '@emotion/styled';

interface WrapperProps {
  error: boolean;
}

interface InputProps {
  value?: string;
  hasError?: boolean;
}

export const Input = styled.input<InputProps>`
  ${({theme, value, hasError}) => css`
    background-color: ${theme.colors.gray1};
    border-radius: 16px;
    border: 2px solid transparent;
    color: ${value && !hasError ? theme.colors.gray8 : theme.colors.gray4};
    cursor: pointer;
    padding: 16px 24px 14px;

    &::-webkit-input-placeholder {
      color: ${theme.colors.gray4};
    }

    &::-webkit-datetime-edit-text,
    &::-webkit-datetime-edit-month-field,
    &::-webkit-datetime-edit-day-field,
    &::-webkit-datetime-edit-year-field {
      color: ${value && !hasError ? theme.colors.gray8 : theme.colors.gray4};
    }

    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  `}
`;

const wrapperModifiers = {
  error: (theme: Theme) => css`
    .date-input {
      border-color: ${theme.colors.red};
      border-width: 2px;
    }
  `,
};

export const Wrapper = styled.div<WrapperProps>`
  ${({theme, error}) => css`
    display: flex;
    flex-direction: column;

    ${error && wrapperModifiers.error(theme)}
  `}
`;

export const ErrorWrapper = styled.span`
  ${({theme}) => css`
    align-items: center;
    color: ${theme.colors.red};
    display: flex;
    margin-top: 8px;

    svg {
      margin-right: 5px;
      width: 15px;
    }
  `}
`;

export const Error = styled.p`
  ${({theme}) => css`
    color: ${theme.colors.red};
    font-size: ${theme.font.sizes.xxsmall};
  `}
`;
