"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Label = exports.Input = exports.Wrapper = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _token = require("@jarvis/token");
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  display: flex;\n  width: 100%;\n  list-style: none;\n  position: relative;\n  user-select: none;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  height: 0;\n  width: 0;\n  opacity: 0;\n  position: absolute;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    background-color: ",
        ";\n    @media (hover: hover) {\n      &:hover {\n        background-color: ",
        ";\n      }\n    }\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    background-color: ",
        ";\n    @media (hover: hover) {\n      &:hover {\n        background-color: ",
        ";\n      }\n    }\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n    width: 100%;\n    padding: 16px 20px;\n    cursor: pointer;\n\n    ",
        "\n  "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
var Wrapper = _styled.default.li(_templateObject());
exports.Wrapper = Wrapper;
var Input = _styled.default.input(_templateObject1());
exports.Input = Input;
var optionsModifiers = {
    white: function(isSelected) {
        return (0, _react).css(_templateObject2(), isSelected ? _token.COLOR_TEXTFIELD_WHITE_HOVER : _token.COLOR_TEXTFIELD_WHITE_DEFAULT, _token.COLOR_TEXTFIELD_WHITE_HOVER);
    },
    gray: function(isSelected) {
        return (0, _react).css(_templateObject3(), isSelected ? _token.COLOR_TEXTFIELD_GRAY_HOVER : _token.COLOR_TEXTFIELD_GRAY_DEFAULT, _token.COLOR_TEXTFIELD_GRAY_HOVER);
    }
};
var Label = _styled.default.label(_templateObject5(), function(param) {
    var isSelected = param.isSelected, colorVariant = param.colorVariant;
    return (0, _react).css(_templateObject4(), optionsModifiers[colorVariant](isSelected));
});
exports.Label = Label;
