"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ErrorWrapper = exports.Input = exports.Wrapper = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _token = require("@jarvis/token");
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  display: flex;\n  flex-direction: column;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n      &:focus {\n        outline: 1px solid ",
        ";\n      }\n    "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n      outline: 1px solid ",
        ";\n    "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n          background-color: ",
        ";\n          @media (hover: hover) {\n            &:hover {\n              background-color: ",
        ";\n            }\n          }\n          &:disabled {\n            background-color: ",
        ";\n          }\n        "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n          background-color: ",
        ";\n          @media (hover: hover) {\n            &:hover {\n              background-color: ",
        ";\n            }\n          }\n          &:disabled {\n            background-color: ",
        ";\n          }\n        "
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n    width: 100%;\n    height: 50px;\n    padding: 0px 20px;\n    border: 0px;\n    border-radius: 16px;\n    color: ",
        ";\n    &::placeholder {\n      color: ",
        ";\n    }\n    &:disabled {\n      cursor: not-allowed;\n    }\n    ",
        "\n    ",
        "\n\n    ",
        "\n  "
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n  display: inline-flex;\n  align-items: center;\n  margin-top: 8px;\n  color: ",
        ";\n  & > svg {\n    width: 15px;\n    margin-right: 5px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
var Wrapper = _styled.default.div(_templateObject());
exports.Wrapper = Wrapper;
var Input = _styled.default.input(_templateObject6(), function(param) {
    var colorVariant = param.colorVariant, isError = param.isError;
    return (0, _react).css(_templateObject5(), _token.COLOR_TEXT_3, _token.COLOR_TEXT_5, !isError && (0, _react).css(_templateObject1(), _token.COLOR_BRAND_1_DEFAULT), isError && (0, _react).css(_templateObject2(), _token.COLOR_BRAND_3_DEFAULT), colorVariant === "white" ? (0, _react).css(_templateObject3(), _token.COLOR_TEXTFIELD_WHITE_DEFAULT, _token.COLOR_TEXTFIELD_WHITE_HOVER, _token.COLOR_TEXTFIELD_WHITE_DISABLED) : (0, _react).css(_templateObject4(), _token.COLOR_TEXTFIELD_GRAY_DEFAULT, _token.COLOR_TEXTFIELD_GRAY_HOVER, _token.COLOR_TEXTFIELD_GRAY_DISABLED));
});
exports.Input = Input;
var ErrorWrapper = _styled.default.div(_templateObject7(), _token.COLOR_BRAND_3_DEFAULT);
exports.ErrorWrapper = ErrorWrapper;
