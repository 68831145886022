import {Student} from '@jarvis/api-adapter';

import {AssignmentDetailsFormData} from '@src/components/organisms/CreateAssignmentDetailsSection';
import {formatFromInputDate, isValidDate, isSameDay, isFuture} from '@src/utils/date';

const PASSWORD_MIN_LENGTH = 8;
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface FieldErrors {
  [key: string]: string;
}

interface ValidateFieldData {
  isValid?: boolean;
  error?: string;
}

export interface ValidateField {
  [key: string]: ValidateFieldData;
}

export const isValidEmail = (value: string) => EMAIL_REGEX.test(value?.toLowerCase());

// eslint-disable-next-line no-useless-escape
export const hasSpecialCharacter = (value: string) => /[^a-zA-Z0-9\-\/]/.test(value);

export const hasNumber = (value: string) => /\d/.test(value);

export const validateEmail = (value: string): ValidateFieldData => {
  if (isValidEmail(value)) {
    return {isValid: true};
  } else {
    return {error: 'Email is not valid'};
  }
};

interface RequiredFieldsOptions {
  customErrorMessage?: string;
  minWordLength?: number;
  maxWordLength?: number;
}

export const validateRequiredFields = (value: string, options?: RequiredFieldsOptions): ValidateFieldData => {
  switch (true) {
    case options && options?.minWordLength && value.length < options.minWordLength:
      return {error: `It should have at least ${options!.minWordLength} characters`};
    case options && options?.maxWordLength && value.length > options.maxWordLength:
      return {error: `It should have a maximum of ${options!.maxWordLength} characters`};
    case !value.length:
      return {error: options?.customErrorMessage || `This is a required field`};
    default:
      return {isValid: true};
  }
};

export const validatePassword = (value: string): ValidateFieldData => {
  if (value.length < PASSWORD_MIN_LENGTH) {
    return {error: `Minimum ${PASSWORD_MIN_LENGTH} characters`};
  }

  if (!hasSpecialCharacter(value)) {
    return {error: 'At least one special character (@, &, etc)'};
  }

  if (!hasNumber(value)) {
    return {error: 'At least one number'};
  }

  return {isValid: true};
};

export const validateConfirmPassword = (newPassword: string, confirmNewPassword: string) => {
  if (!confirmNewPassword) return {error: 'This is a required field'};
  if (newPassword !== confirmNewPassword) return {error: 'Passwords do not match'};
  return {isValid: true};
};

interface SignInFormValidateData {
  email: string;
  password: string;
}

export const signInFormValidate = (formData: SignInFormValidateData): ValidateField => ({
  email: validateEmail(formData.email),
  password: validateRequiredFields(formData.password),
});

interface StudentSignUpFormValidateData {
  password: string;
  confirmPassword: string;
}

export const studentSignUpFormValidate = (formData: StudentSignUpFormValidateData): ValidateField => ({
  password: validatePassword(formData.password),
  confirmPassword: validateConfirmPassword(formData.password, formData.confirmPassword),
});

export const inputDateValidate = (inputValue: string, options = {allowSameDay: true}): ValidateFieldData => {
  const date = formatFromInputDate(inputValue);

  if (!isValidDate(date)) {
    return {error: 'Not a valid date'};
  }

  if (options.allowSameDay && isSameDay(date, new Date())) {
    return {isValid: true};
  }

  if (!isFuture(date)) {
    return {error: 'You should input a future date'};
  }

  return {isValid: true};
};

interface CreateAssignmentFormData {
  title: string;
  dueDate: string;
  selectedStudents: Student[];
}

export const createAssignmentFormDataValidate = (formData: CreateAssignmentFormData) => {
  const errors: FieldErrors = {};

  if (!formData.title) {
    errors.title = 'Title is required';
  }

  const dueDateError = inputDateValidate(formData.dueDate);
  if (dueDateError) {
    errors.dueDate = dueDateError.error ?? '';
  }

  if (!formData.selectedStudents.length) {
    errors.selectedStudents = 'You must select at least one student';
  }

  return errors;
};

export const createAssignmentDetailsFormValidate = (formData: AssignmentDetailsFormData) => {
  const errors: Partial<Record<keyof AssignmentDetailsFormData, ValidateFieldData>> = {};

  errors.title = validateRequiredFields(formData?.title ?? '', {
    customErrorMessage: 'Need an Assignment Title',
    maxWordLength: 100,
  });

  if (!formData.dueDate) {
    errors.dueDate = {error: 'Need a Due Date'};
  } else {
    errors.dueDate = inputDateValidate(formData.dueDate);
  }

  const numberOfQuestions = Number(formData.numberOfQuestions);

  if (numberOfQuestions > 0) {
    errors.numberOfQuestions = {isValid: true};
  } else {
    errors.numberOfQuestions = {error: 'Number of Questions should be greater than 0'};
  }

  return errors;
};

export const validationHasError = (validationFields: ValidateField): boolean => {
  const validationValues = Object.values(validationFields);

  if (!validationValues.length) return true;
  return !validationValues.every(field => !!field?.isValid);
};
