"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.TypographyTagVariant = exports.TypographyStylesByVariant = void 0;
var ds = _interopRequireWildcard(require("@jarvis/token"));
function _interopRequireWildcard(obj) {
    if (obj && obj.__esModule) {
        return obj;
    } else {
        var newObj = {};
        if (obj != null) {
            for(var key in obj){
                if (Object.prototype.hasOwnProperty.call(obj, key)) {
                    var desc = Object.defineProperty && Object.getOwnPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : {};
                    if (desc.get || desc.set) {
                        Object.defineProperty(newObj, key, desc);
                    } else {
                        newObj[key] = obj[key];
                    }
                }
            }
        }
        newObj.default = obj;
        return newObj;
    }
}
var TypographyStylesByVariant = {
    h0: {
        fontSize: ds.FONT_SIZE_H0
    },
    h1: {
        fontSize: ds.FONT_SIZE_H1
    },
    h2: {
        fontSize: ds.FONT_SIZE_H2
    },
    h3: {
        fontSize: ds.FONT_SIZE_H3
    },
    h4: {
        fontSize: ds.FONT_SIZE_H4
    },
    body1: {
        fontSize: ds.FONT_SIZE_BODY1
    },
    body2: {
        fontSize: ds.FONT_SIZE_BODY2
    },
    body3: {
        fontSize: ds.FONT_SIZE_BODY3
    },
    caption1: {
        fontSize: ds.FONT_SIZE_CAPTION1
    },
    caption2: {
        fontSize: ds.FONT_SIZE_CAPTION2
    },
    caption3: {
        fontSize: ds.FONT_SIZE_CAPTION3
    }
};
exports.TypographyStylesByVariant = TypographyStylesByVariant;
var TypographyTagVariant = {
    h0: "span",
    h1: "h1",
    h2: "h2",
    h3: "h3",
    h4: "h4",
    body1: "p",
    body2: "p",
    body3: "p",
    caption1: "span",
    caption2: "span",
    caption3: "span"
};
exports.TypographyTagVariant = TypographyTagVariant;
