"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.ButtonBase = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n      width: 100%;\n    "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  display: inline-flex;\n  align-items: center;\n  padding: 0;\n  margin: 0;\n  border: 0;\n  background: none;\n  cursor: pointer;\n\n  :disabled {\n    cursor: not-allowed;\n  }\n\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
var ButtonBase = _styled.default.button(_templateObject1(), function(param) {
    var isFullWidth = param.isFullWidth;
    return isFullWidth && (0, _react).css(_templateObject());
});
exports.ButtonBase = ButtonBase;
