"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Options = exports.DropdownButtonIcon = exports.DropdownButtonText = exports.DropdownButton = exports.Wrapper = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _token = require("@jarvis/token");
var _buttonBase = _interopRequireDefault(require("../ButtonBase"));
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n  position: relative;\n  width: 100%;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n    background-color: ",
        ";\n  "
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _taggedTemplateLiteral([
        "\n    background-color: ",
        ";\n  "
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _taggedTemplateLiteral([
        "\n    display: flex;\n    justify-content: ",
        ";\n    align-items: center;\n    width: 100%;\n    height: 50px;\n    padding: 0px 20px;\n    border: 0;\n    border-radius: 16px;\n    background-color: ",
        ";\n    color: ",
        ";\n    cursor: pointer;\n    @media (hover: hover) {\n      &:hover {\n        ",
        ";\n      }\n    }\n  "
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _taggedTemplateLiteral([
        "\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _taggedTemplateLiteral([
        "\n    width: 6px;\n    color: ",
        ";\n    transform: ",
        ";\n    transition: transform 0.1s ease-in-out;\n  "
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _taggedTemplateLiteral([
        "\n    position: absolute;\n    left: 0;\n    right: 0;\n    display: ",
        ";\n    padding: 0;\n    margin-top: 8px;\n    width: 100%;\n    max-height: 250px;\n    border-radius: 16px;\n    background-color: ",
        ";\n    overflow: auto;\n    z-index: 20;\n    list-style: none;\n    filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.07));\n\n    &::-webkit-scrollbar {\n      display: none; /* Chrome, Safari and Opera */\n    }\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n  "
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
var Wrapper = _styled.default.div(_templateObject());
exports.Wrapper = Wrapper;
var dropdownButtonModifiers = {
    white: (0, _react).css(_templateObject1(), _token.COLOR_TEXTFIELD_WHITE_HOVER),
    gray: (0, _react).css(_templateObject2(), _token.COLOR_TEXTFIELD_GRAY_HOVER)
};
var DropdownButton = (0, _styled).default(_buttonBase.default)(_templateObject4(), function(param) {
    var colorVariant = param.colorVariant, isValue = param.isValue, isPlaceholder = param.isPlaceholder;
    return (0, _react).css(_templateObject3(), isPlaceholder || isValue ? "space-between" : "flex-end", colorVariant === "white" ? _token.COLOR_TEXTFIELD_WHITE_DEFAULT : _token.COLOR_TEXTFIELD_GRAY_DEFAULT, isValue ? _token.COLOR_TEXT_3 : _token.COLOR_TEXT_5, dropdownButtonModifiers[colorVariant]);
});
exports.DropdownButton = DropdownButton;
var DropdownButtonText = _styled.default.span(_templateObject5());
exports.DropdownButtonText = DropdownButtonText;
var DropdownButtonIcon = _styled.default.span(_templateObject7(), function(param) {
    var isOpen = param.isOpen;
    return (0, _react).css(_templateObject6(), _token.COLOR_TEXT_3, isOpen ? "rotate(-90deg)" : "rotate(90deg)");
});
exports.DropdownButtonIcon = DropdownButtonIcon;
var Options = _styled.default.ul(_templateObject9(), function(param) {
    var isOpen = param.isOpen, colorVariant = param.colorVariant;
    return (0, _react).css(_templateObject8(), isOpen ? "grid" : "none", colorVariant === "white" ? _token.COLOR_TEXTFIELD_WHITE_DEFAULT : _token.COLOR_TEXTFIELD_GRAY_DEFAULT);
});
exports.Options = Options;
