import React, {InputHTMLAttributes} from 'react';
import {Label} from '@jarvis/core';

import {AlertTriangleIcon} from '@src/components/icons';
import {useBoolean} from '@src/hooks/useBoolean';

import * as S from './DateInput.styles';

export interface DateInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value?: string;
  label?: string;
  error?: string;
  required?: boolean;
  min?: string | number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const DateInput = ({value, name, label, required = false, error, min, onChange, onBlur}: DateInputProps) => {
  const [hasTypedAnyValue, setHasTypedAnyValue] = useBoolean(false);
  const [visited, setVisited] = useBoolean(false);

  const showError = !!error && (visited || hasTypedAnyValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;
    if (newValue === value) return;

    onChange(event);
    !hasTypedAnyValue && setHasTypedAnyValue.setTrue();
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    !visited && setVisited.setTrue();
    onBlur?.(event);
  };

  return (
    <S.Wrapper error={showError}>
      {!!label && (
        <Label htmlFor={name} required={required}>
          {label}
        </Label>
      )}
      <S.Input
        className="date-input"
        type="date"
        id={name}
        name={name}
        value={value}
        min={min}
        placeholder="MM/DD/YYYY"
        onChange={handleChange}
        onBlur={handleBlur}
        required={required}
        hasError={showError}
      />
      {showError && (
        <S.ErrorWrapper>
          <AlertTriangleIcon />
          <S.Error>{error}</S.Error>
        </S.ErrorWrapper>
      )}
    </S.Wrapper>
  );
};

export default DateInput;
