"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.useWindowResizeEffect = void 0;
var _react = require("react");
var useWindowResizeEffect = function(onWindowResize) {
    (0, _react).useEffect(function() {
        window.addEventListener("resize", onWindowResize);
        return function() {
            window.removeEventListener("resize", onWindowResize);
        };
    }, [
        onWindowResize
    ]);
};
exports.useWindowResizeEffect = useWindowResizeEffect;
