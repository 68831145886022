"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.Label = void 0;
var _react = require("@emotion/react");
var _styled = _interopRequireDefault(require("@emotion/styled"));
var _token = require("@jarvis/token");
function _interopRequireDefault(obj) {
    return obj && obj.__esModule ? obj : {
        default: obj
    };
}
function _taggedTemplateLiteral(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _taggedTemplateLiteral([
        "\n    color: ",
        ";\n    cursor: ",
        ";\n    margin-bottom: 8px;\n    user-select: none;\n  "
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _taggedTemplateLiteral([
        "\n  ",
        "\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
var Label = _styled.default.label(_templateObject1(), function(param) {
    var isDisabled = param.isDisabled, hasHtmlFor = param.hasHtmlFor;
    return (0, _react).css(_templateObject(), _token.COLOR_TEXT_3, isDisabled || !hasHtmlFor ? "not-allowed" : "pointer");
});
exports.Label = Label;
