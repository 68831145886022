import React from 'react';

import TextField from '@src/components/atoms/TextField';
import CreateAssignmentFormSection from '@src/components/molecules/CreateAssignmentFormSection';
import {createAssignmentDetailsFormValidate} from '@src/utils/validations';
import DateInput from '@src/components/atoms/DateInput';

import * as S from './CreateAssignmentDetailsSection.styles';

export interface AssignmentDetailsFormData {
  title?: string | undefined;
  dueDate?: string | undefined;
  numberOfQuestions?: string | undefined;
}

export const initialAssignmentDetailsFormData: AssignmentDetailsFormData = {
  title: '',
  dueDate: '',
  numberOfQuestions: '',
};

export interface CreateAssignmentDetailsSectionProps {
  assignmentDetailsFormData?: AssignmentDetailsFormData;
  onAssignmentDetailsInputChange: (value: string, field: string) => void;
  isAssignmentCreated: boolean;
}

const minDateToday = new Date().toISOString().split('T')[0];

const CreateAssignmentDetailsSection = ({
  assignmentDetailsFormData = initialAssignmentDetailsFormData,
  onAssignmentDetailsInputChange,
  isAssignmentCreated,
}: CreateAssignmentDetailsSectionProps) => {
  const formSectionValidation = createAssignmentDetailsFormValidate(assignmentDetailsFormData);

  const handleChangeDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target?.value) {
      onAssignmentDetailsInputChange('', 'dueDate');
    }
    onAssignmentDetailsInputChange(event.target?.value, 'dueDate');
  };

  return (
    <CreateAssignmentFormSection sectionId="assignment-details" index={2} title="Assignment Details">
      <S.Wrapper>
        <TextField
          label="Assignment Title"
          placeholder="Assignment Title"
          name="title"
          value={assignmentDetailsFormData.title}
          maxLength={100}
          error={isAssignmentCreated ? '' : formSectionValidation.title?.error}
          onChange={onAssignmentDetailsInputChange}
          required
        />
        <DateInput
          id="dueDate"
          name="dueDate"
          label="Due Date"
          value={assignmentDetailsFormData.dueDate}
          error={isAssignmentCreated ? '' : formSectionValidation.dueDate?.error}
          min={minDateToday}
          onChange={handleChangeDate}
          required
        />
        <TextField
          label="Number of Questions"
          placeholder="0"
          name="numberOfQuestions"
          type="number"
          min={0}
          value={assignmentDetailsFormData.numberOfQuestions}
          error={isAssignmentCreated ? '' : formSectionValidation.numberOfQuestions?.error}
          onChange={onAssignmentDetailsInputChange}
          required
        />
      </S.Wrapper>
    </CreateAssignmentFormSection>
  );
};

export default CreateAssignmentDetailsSection;
